import React from 'react';

export type OverlayProps = {
  children: React.ReactNode;
  active: boolean;
  onClick: () => void;
}

const Overlay: React.FC<OverlayProps> = ({ children, active, onClick }) => {
  return (
    <div className={`absolute xs:right-0 z-10 bg-white h-[85%] xs:h-full xs:rounded-none rounded-t bottom-0 w-full xs:max-w-[520px] xs: duration-300 transition-all 
      ${active ? 'translate-y-0 xs:translate-x-0' : 'translate-y-full invisible xs:translate-x-full xs:-translate-y-0'}`}>
      <button
        onClick={onClick}
        className="absolute top-7 xs:top-11 right-7 xs:right-11 p-3 rounded-full z-[1] bg-white">
        <img src='./images/Close.svg'></img>
      </button>
      <div className="absolute overflow-y-auto w-full pt-20 pb-7 px-7 xs:pt-[120px] xs:pl-20 xs:pr-[100px] h-full top-0">
        {children}
      </div>
    </div>
  )
}

export default Overlay;