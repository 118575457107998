import React, { useEffect, useRef, useState } from 'react';
import "./Quiz.css";
import questions from './questions.json';
import { Button, Option } from '../../helper';
import { Link, Navigate } from 'react-router-dom';
import AnimateHeight from 'react-animate-height';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

export type Question = {
  question: string;
  options: string[];
  answer: number;
}

const Quiz: React.FC = () => {
  const [randomQues, setRandomQues] = useState<Question[]>([]);
  const [index, setIndex] = useState<number>(0);
  const [activeOption, setActiveOption] = useState<number>();
  const [quesMaxHeight, setQuesMaxHeight] = useState<number>(0);
  const [optionsMaxHeight, setOptionsMaxHeight] = useState<number>(0);
  const quesRef = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);
  const [score, setScore] = useState<number>(0);

  const LIMIT = 3;

  useEffect(() => {
    const _randomQuestions = [];
    const _questions = [...questions];

    for (let i = 0; i < LIMIT; i++) {
      _randomQuestions[i] = _questions.splice(Math.floor(Math.random() * (questions.length - i)), 1)[0];
    }

    setRandomQues(_randomQuestions);
  }, [])

  useEffect(() => {
    if (index == 0 && quesRef && quesRef.current && quesRef.current.clientHeight) {
      setQuesMaxHeight(quesRef.current.clientHeight);
    }
    if (index == 0 && optionsRef && optionsRef.current && optionsRef.current.clientHeight) {
      setOptionsMaxHeight(optionsRef.current.clientHeight);
    }
  }, [randomQues, index])

  useEffect(() => {
    if (localStorage.getItem('finished') !== 'true') {
      localStorage.setItem('score', JSON.stringify(score));
    }

  }, [score])

  const getSteps = (): number[] => {
    const _steps = [];
    for (let i = 0; i < LIMIT; i++) {
      _steps[i] = i + 1;
    }
    return _steps;
  }

  const handleContinue = () => {
    const selectedOption = activeOption;
    if (selectedOption != undefined && selectedOption + 1 === randomQues[index].answer) {
      setScore(score + 1);
    }
    setIndex(index + 1);
    if (index + 1 < LIMIT) {
      setActiveOption(undefined)
    }
  }

  if (localStorage.getItem('finished') === 'true') {
    return <Navigate to="/result" />
  }

  return (
    <div className='relative w-full h-full flex items-center justify-center'>
      <div className="page-bg absolute w-full h-full" />
      <div className="flex page-content my-4 flex-col items-center w-full bg-white rounded shadow max-w-[352px] z-[0] overflow-hidden">
        <div className="p-6 w-full h-full bg-light-grey flex flex-col items-center gap-6 shadow">
          <img src="./images/Vlogo.svg" width={35} height={31} />
          <AnimateHeight duration={300} height={quesMaxHeight || 10}>
            <SwitchTransition>
              <CSSTransition
                key={index}
                timeout={300}
                classNames="slide-up"
                unmountOnExit
                onEnter={(node: HTMLElement) => { setQuesMaxHeight(node.clientHeight) }}
              >
                <div className="slide-up">
                  <div ref={quesRef} className={`text-lg font-bold text-center w-full`}>
                    {randomQues && randomQues.length && randomQues[index] && randomQues[index].question}
                  </div>
                </div>
              </CSSTransition>
            </SwitchTransition>
          </AnimateHeight>
          <div className='inline-flex gap-1'>
            {
              getSteps().filter((step: number) => step < LIMIT).map((step: number) => {
                return (
                  <div key={`step-${step + 1}`} className='inline-flex items-center gap-1'>
                    <div className={`transition-opacity duration-300 text-xs inline-flex w-5 h-5 pt-[2px] rounded-full items-center justify-center ${step - 1 <= index ? "opacity-100" : "opacity-[15%]"} bg-blue font-bold text-white`}>{step}</div>
                    <div className={`transition-opacity duration-300 h-[2px] w-6 ${step - 1 <= index ? "opacity-100" : "opacity-[15%]"} bg-blue`}></div>
                  </div>
                )
              })
            }
            <div key={`step-${LIMIT}`} className='inline-flex items-center gap-1'>
              <div className={`transition-opacity duration-300 text-xs inline-flex w-5 h-5 pt-[2px] rounded-full items-center justify-center ${LIMIT - 1 == index ? "opacity-100" : "opacity-[15%]"} bg-blue font-bold text-white`}>{LIMIT}</div>
            </div>
          </div>
        </div>
        <div className='px-6 w-full flex flex-col items-center justify-center'>
          <AnimateHeight className='w-full' duration={300} height={optionsMaxHeight || 10}>
            <SwitchTransition>
              <CSSTransition
                key={index + 'options'}
                timeout={300}
                classNames="slide-up"
                unmountOnExit
                onEnter={(node: HTMLElement) => { setOptionsMaxHeight(node.clientHeight) }}
              >
                <div className="slide-up">
                  <div ref={optionsRef} className="py-6 flex flex-col w-full gap-[10px]">
                    {randomQues && randomQues.length && randomQues[index] && randomQues[index].options.map((option: string, idx: number) => <Option key={`option-${idx}`} active={idx === activeOption} onClick={() => setActiveOption(idx)}>{option}</Option>)}
                  </div>
                </div>
              </CSSTransition>
            </SwitchTransition>
          </AnimateHeight>
          {
            index + 1 === LIMIT ? (
              <Link
                className={`${(activeOption == undefined) && 'pointer-events-none'}`}
                to="/result">
                <Button onClick={() => handleContinue()} disabled={activeOption == undefined} >Weiter</Button>
              </Link>
            ) : (
              <div>
                <Button onClick={() => handleContinue()} disabled={activeOption == undefined}>Weiter</Button>
              </div>
            )
          }
          <div className="mb-8"></div>
        </div>
      </div>
    </div>
  )
}

export default Quiz;