import React from 'react';
import { Navigate, redirect } from 'react-router';
import './Result.css';


const Result: React.FC = () => {

  const CUT_OFF = 2;
  const score = localStorage.getItem('score');
  if (score === 'undefined') {
    return <Navigate to="/"/>;
  } 
  localStorage.setItem('finished', 'true');
  const won = JSON.parse(localStorage.getItem('score') || '0') >= CUT_OFF;
  return (
    <div className={`relative w-full h-full overflow-hidden flex items-center justify-center ${won ? 'bg-green' : 'bg-red'}`}>
      {won ? (
        <div className="page-bg winner-bg absolute w-full h-full" />
      ) : (
        <div className="page-bg loser-bg absolute w-full h-full" />
      )}
      <div className="flex page-content flex-col items-center w-full mx-3 p-12 bg-white rounded shadow max-w-[352px] z-[0]">
        <img src="./images/Logo.svg" width={174} height={115} />
        {won ? (
          <img srcSet='./images/Gift@2x.png 2x, ./images/Gift@3x.png 3x' src="./images/Gift.png" className='mt-8 mb-2 jump-shake' width={60} height={76} />
        ) : (
          <img srcSet='./images/Cat@2x.png 2x, ./images/Cat@3x.png 3x' src="./images/Cat.png" className='mt-7 mb-1 zoom-in' width={64} height={82} />
        )}
        <div className="text-center font-bolder tracking-widest uppercase text-md">
          {won ? 'gratuliere!' : 'schade!'}
        </div>
        {
          won ? (
            <>
              <div className='text-center mt-2'>
                Du hast <span className="font-bolder">{score && score === '2' ? 'zwei' : 'drei'} von drei Fragen richtig</span> beantwortet. Zeige diesen Screen hier vor Ort einer Volksbank-MitarbeiterIn und Du bekommst sofort Dein Geschenk.
              </div>
            </>
          ) : (
            <>
              <div className='text-center mt-2'>
                Du hast <span className="font-bolder">{score && score === '1' ? 'eine' : 'keine'} der drei Fragen richtig</span> beantwortet. Das reicht leider nicht für das Sofortgeschenk.
              </div><div className='text-center mt-5 font-bolder'>
                Um das BanQuiz neu zu starten, beginne von vorne und scanne nochmals den QR-Code.
              </div>
            </>
          )
        }
      </div>
    </div>
  )
}

export default Result;