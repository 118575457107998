import React from 'react';

export type ButtonProps = {
  colorClassName?:string,
  bgClassName?:string,
  size?: string,
  children?: React.ReactNode,
  disabled?: boolean,
  onClick?: () => void
}

const Button: React.FC<ButtonProps> = ({ colorClassName, bgClassName, size, children, onClick, disabled }) => {
  return (
    <div className={`relative ${size === 'large' ? 'min-w-[236px]' : 'min-w-[188px]'} duration-300 transition-all ${disabled && "opacity-30 pointer-events-none"} w-full z-[0]`}>
      <button
        disabled={disabled}
        onClick={onClick}
        className={`bg-blue text-white absolute top-0 left-0 rounded-full text-center  w-full uppercase font-bolder 
            relative  cursor-pointer active:brightness-95 active:translate-y-1 transition-all
            ${size === "large" ? 'text-btn-lg tracking-wider py-7' : 'tracking-wide py-6'} ${bgClassName} ${colorClassName}`}>
        {children}
      </button>
      <div className={`absolute w-full h-full bg-blue rounded-full top-2 left-0 z-[-1] opacity-50 ${bgClassName}`}>
      </div>
    </div>
  )
}

export default Button;