import React from 'react';

export type OptionProps = {
  children: React.ReactNode;
  active: boolean;
  onClick: () => void;
}

const Option: React.FC<OptionProps> = ({ children, active, onClick }) => {
  return (
    <button onClick={onClick} className={`font-bold px-6 h-20 text-center w-full hyphens-auto
    ${active && 'bg-powder-blue'} border-[2px] border-powder-blue rounded-3xl hover:bg-powder-blue`}>
      {children}
    </button>
  )
}

export default Option;