import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Overlay } from '../../helper';
import './Home.css';

const Home: React.FC = () => {
  const [showPC, setShowPC] = useState(false);
  const [start, setStart] = useState(false);

  useEffect(() => {
    localStorage.setItem('score', 'undefined');
    localStorage.setItem('finished', 'false');
  }, [])

  return (
    <div className='relative w-full h-full overflow-hidden flex items-center justify-center'>
      <div className="page-bg absolute w-full h-full" /> {/** add 'v-bg' for v logo background*/}
      <div onClick={() => setShowPC(false)} className={`bg-black ${showPC ? 'opacity-50' : 'opacity-0 invisible'} z-10 absolute top-0 left-0 w-full h-full duration-300 transition-all`}></div>
      <div className="flex page-content flex-col items-center w-full mx-3 p-12 bg-white rounded shadow max-w-[352px] z-[0]">
        <img src="./images/Logo.svg" width={218} height={132} />
        <div className='text-center mt-9 mb-8'>
          <span className='font-bolder'> Herzlich Willkommen zum BanQuiz.</span> Beantworte 2 von 3 Fragen richtig und hole Dir Dein <span className='font-bolder'>Sofortgeschenk!</span>
        </div>
        <Link className='w-full' to="/quiz"><Button onClick={() => setStart(true)} size='large'>start</Button></Link>
        <button onClick={() => setShowPC(true)} className="text-sm mt-6 underline cursor-pointer">
          Teilnahmebedingungen
        </button>
      </div>
      <Overlay active={showPC} onClick={() => setShowPC(false)}>
        <div className="text-lg font-bold w-2/3">
          Teilnahme-bedingungen
        </div>
        <div className="mt-6 grid grid-cols-1 gap-4">
          <div>
            Der Veranstalter des Gewinnspiels „BanQuiz“ ist die Volksbank Salzburg eG, St.-Julien-Straße 12, 5020 Salzburg. Eine Teilnahme am Gewinnspiel „BanQuiz“ ist ausschließlich zu den hier angeführten Bedingungen möglich. Mit der Teilnahme am Gewinnspiel „BanQuiz“ stimmt der Teilnehmer diesen Teilnahmebedingungen ausdrücklich zu. Der Veranstalter Volksbank Salzburg eG behält sich vor, das Gewinnspiel „BanQuiz“ zu jedem Zeitpunkt ohne Vorankündigung und ohne Angabe von Gründen abzubrechen oder zu beenden.
            Von der Teilnahme ausgeschlossen sind Mitarbeiter des Volksbanken Kreditinstitut-Verbundes.
          </div>
          <div>
            Die Teilnahme ist nicht abhängig vom Abschluss eines Vertrages mit der Volksbank Salzburg eG. Die Gewinnchancen werden dadurch auch nicht erhöht.
          </div>
          <div>
            Es besteht kein Anspruch auf Aushändigung eines bestimmten Sofortpreises, sind mehrere verschiedene Sofortpreise vorhanden, wählt die Volksbank Salzburg eG den Sofortgewinn aus. Eine Barauszahlung oder ein Umtausch der Sofortgewinne ist nicht möglich. Der Rechtsweg ist ausgeschlossen. Über das Gewinnspiel „BanQuiz“ kann kein Schriftverkehr mit der Volksbank Salzburg eG geführt werden beziehungsweise werden das Gewinnspiel „BanQuiz“  betreffende Schreiben von der Volksbank Salzburg eG nicht beantwortet. Im Rahmen des Gewinnspiels „BanQuiz“  werden personenbezogenen Daten von Teilnehmern weder erhoben noch gespeichert.        </div>
        </div>
      </Overlay>
    </div>
  );
}

export default Home;