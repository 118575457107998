import React from 'react';
import { Routes, Route, useLocation, useOutlet } from 'react-router-dom';
import Home from './pages/home';
import Quiz from './pages/quiz';
import Result from './pages/result';
import { CSSTransition, SwitchTransition } from 'react-transition-group';


const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<TransitionWraper />}>
        <Route index element={<Home />} />
        <Route path="quiz" element={<Quiz />} />
        <Route path="result" element={<Result />} />
        <Route path="*" element={<Home />} />
      </Route>
    </Routes>
  )
}

const TransitionWraper: React.FC = () => {
  const location = useLocation();
  const currentOutlet = useOutlet();
  return (
    <SwitchTransition>
      <CSSTransition
        key={location.pathname}
        timeout={300}
        classNames="page"
        unmountOnExit
      >
        <div className="page">
          {currentOutlet}
        </div>
      </CSSTransition>
    </SwitchTransition>
  )
}

export default App;